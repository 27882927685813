<template>
  <div>
    <div>
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        hideRequiredMark
      >
        <a-form-model-item ref="name" label="企业名称" prop="enterpriseName">
          <div class="item-style flex-wrap flex-h-center">
            <div class="flex-wrap flex-center item-icon">
              <img src="@/assets/auth3.png" alt="" width="25" height="22" />
            </div>
            <a-input
              v-model="form.enterpriseName"
              placeholder="请输入企业名称"
              :maxLength="60"
            />
          </div>
        </a-form-model-item>

        <a-form-model-item ref="name" label="统一社会信用代码" prop="trustCode">
          <div class="item-style flex-wrap flex-h-center">
            <div class="flex-wrap flex-center item-icon">
              <img src="@/assets/auth8.png" alt="" width="22" height="23" />
            </div>
            <a-input
              v-model="form.trustCode"
              placeholder="请输入统一社会信用代码"
              :maxLength="18"
            />
          </div>
        </a-form-model-item>

        <a-form-model-item
          ref="name"
          label="营业执照"
          prop="cardPic"
          class="img-upload"
        >
          <image-upload
            class="avatar-uploader"
            text="上传营业执照"
            :bizPath="bizPath"
            v-model="form.cardPic"
            :showPreview="true"
          ></image-upload>
        </a-form-model-item>

        <a-form-model-item
          ref="name"
          label="法人姓名"
          prop="representativeName"
        >
          <div class="item-style flex-wrap flex-h-center">
            <div class="flex-wrap flex-center item-icon">
              <img src="@/assets/auth5.png" alt="" width="24" height="17" />
            </div>
            <a-input
              v-model="form.representativeName"
              placeholder="请输入法人姓名"
              :maxLength="30"
            />
          </div>
        </a-form-model-item>

        <a-form-model-item
          ref="name"
          label="法人身份证号码"
          prop="representativeCardid"
        >
          <div class="item-style flex-wrap flex-h-center">
            <div class="flex-wrap flex-center item-icon">
              <img src="@/assets/auth6.png" alt="" width="23" height="16" />
            </div>
            <a-input
              v-model="form.representativeCardid"
              placeholder="请输入法人身份证号码"
              :maxLength="18"
            />
          </div>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>
<script>
import reg from '@/api/regular'
import { mapActions, mapGetters } from 'vuex'
import ImageUpload from '@/components/Assembly/ImageUpload'
export default {
  components: {
    ImageUpload,
  },
  data() {
    let validateImg = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请上传营业执照'))
      } else {
        callback()
      }
    }
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 14 },
      form: {
        enterpriseName: '',
        trustCode: '',
        cardPic: '',
        representativeName: '',
        representativeCardid: '',
      },
      rules: {
        enterpriseName: [
          {
            required: true,
            message: '请输入企业名称',
            trigger: 'blur',
          },
        ],
        trustCode: [
          {
            required: true,
            message: '请输入统一社会信用代码',
            trigger: 'blur',
          },
          {
            required: true,
            pattern: reg.charterNum,
            message: '请输入正确的统一社会信用代码',
            trigger: 'blur',
          },
        ],
        cardPic: [{ validator: validateImg, trigger: 'change' }],
        representativeName: [
          {
            required: true,
            message: '请输入法人姓名',
            trigger: 'blur',
          },
        ],
        representativeCardid: [
          {
            required: true,
            message: '请输入法人身份证号码',
            trigger: 'blur',
          },
          {
            required: true,
            pattern: reg.cardNum,
            message: '请输入正确的法人身份证号码',
            trigger: 'blur',
          },
        ],
      },
      bizPath: '',
    }
  },
  created() {
    this.bizPath = this.$currentDate.getDate()
  },
  methods: {
    ...mapActions(['EnterpriseAuth', 'GetUserAuthStatus', 'GetRole']),
    ...mapGetters(['userInfo']),
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
          }
          this.EnterpriseAuth(params)
            .then((res) => {
              if (res.success) {
                this.getAuth()
                this.getRole()
                this.$router.push({ path: '/personalCenter' })
              } else {
                this.$message.error(res.message)
              }
            })
            .catch((err) => {
              this.$message.error(err.message)
            })
        } else {
          return false
        }
      })
    },
    getAuth() {
      const id = this.userInfo().id
      this.GetUserAuthStatus(id)
        .then((res) => {})
        .catch((err) => {})
    },
    getRole() {
      const id = this.userInfo().id
      this.GetRole(id)
        .then((res) => {})
        .catch((err) => {})
    },
    empty() {
      this.$refs.ruleForm.clearValidate()
      this.form = {
        enterpriseName: '',
        trustCode: '',
        cardPic: '',
        representativeName: '',
        representativeCardid: '',
      }
    },
  },
}
</script>
<style lang="less" scoped></style>
