<template>
  <div>
    <div>
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        hideRequiredMark
      >
        <a-form-model-item label="姓名" prop="examUserName">
          <div class="item-style flex-wrap flex-h-center">
            <div class="flex-wrap flex-center item-icon">
              <img src="@/assets/auth5.png" alt="" width="24" height="17" />
            </div>
            <a-input
              v-model="form.examUserName"
              placeholder="请输入姓名"
              :maxLength="30"
            />
          </div>
        </a-form-model-item>

        <a-form-model-item label="身份证号码" prop="cardId">
          <div class="item-style flex-wrap flex-h-center">
            <div class="flex-wrap flex-center item-icon">
              <img src="@/assets/auth6.png" alt="" width="23" height="16" />
            </div>
            <a-input
              v-model="form.cardId"
              placeholder="请输入身份证号码"
              :maxLength="18"
            />
          </div>
        </a-form-model-item>

        <a-form-model-item
          label="证件照片"
          prop="certificates"
          class="img-upload"
        >
          <a-row :gutter="24">
            <a-col :span="12">
              <image-upload
                class="avatar-uploader"
                text="上传身份证人像面"
                :bizPath="bizPath"
                v-model="form.cardUpPic"
                :showPreview="true"
              ></image-upload>
            </a-col>
            <a-col :span="12">
              <image-upload
                class="avatar-uploader"
                text="上传身份证国徽面"
                :bizPath="bizPath"
                v-model="form.cardDownPic"
                :showPreview="true"
              ></image-upload>
            </a-col>
          </a-row>
        </a-form-model-item>

        <a-form-model-item label="所属企业" prop="enterpriseId">
          <div class="item-style flex-wrap flex-h-center">
            <div class="flex-wrap flex-center item-icon">
              <img src="@/assets/auth3.png" alt="" width="25" height="22" />
            </div>
            <a-select
              v-model="form.enterpriseId"
              placeholder="请选择所属企业"
              @change="handleChange"
            >
              <a-select-option
                v-for="item in enterpriseList"
                :key="item.id"
                :data="item"
              >
                {{ item.enterpriseName }}
              </a-select-option>
            </a-select>
          </div>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>
<script>
import reg from '@/api/regular'
import { mapActions, mapGetters } from 'vuex'
import ImageUpload from '@/components/Assembly/ImageUpload'
import { yqmsEnterpriseListAll } from '@/api/auth'
export default {
  components: {
    ImageUpload,
  },
  data() {
    let validateImg = (rule, value, callback) => {
      if (this.form.cardUpPic === '') {
        callback(new Error('请上传身份证人像面'))
      } else if (this.form.cardDownPic === '') {
        callback(new Error('请上传身份证国徽面'))
      } else {
        callback()
      }
    }
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 14 },
      form: {
        examUserName: '',
        cardId: '',
        cardUpPic: '',
        cardDownPic: '',
        enterpriseId: undefined,
        enterpriseName: '',
      },
      rules: {
        examUserName: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur',
          },
        ],
        cardId: [
          {
            required: true,
            message: '请输入身份证号码',
            trigger: 'blur',
          },
          {
            required: true,
            pattern: reg.cardNum,
            message: '请输入正确的身份证号码',
            trigger: 'blur',
          },
        ],
        certificates: [{ validator: validateImg, trigger: 'change' }],
        enterpriseId: [
          {
            required: true,
            message: '请选择所属企业',
            trigger: 'change',
          },
        ],
      },
      enterpriseList: [],
      bizPath: '',
    }
  },
  created() {
    this.bizPath = this.$currentDate.getDate()
    this.getList()
  },
  methods: {
    ...mapActions(['PersonalAuth', 'GetUserAuthStatus', 'GetRole']),
    ...mapGetters(['userInfo']),
    getList() {
      yqmsEnterpriseListAll()
        .then((res) => {
          if (res.code == 200) {
            this.enterpriseList = res.result
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
          }
          this.PersonalAuth(params)
            .then((res) => {
              if (res.success) {
                this.getAuth()
                this.getRole()
                this.$router.push({ path: '/personalCenter' })
              }
            })
            .catch((err) => {
              this.$message.error(err.message)
            })
        }
      })
    },
    getAuth() {
      const id = this.userInfo().id
      this.GetUserAuthStatus(id)
        .then((res) => {})
        .catch((err) => {})
    },
    getRole() {
      const id = this.userInfo().id
      this.GetRole(id)
        .then((res) => {})
        .catch((err) => {})
    },
    empty() {
      this.$refs.ruleForm.clearValidate()
      this.form = {
        examUserName: '',
        cardId: '',
        cardUpPic: '',
        cardDownPic: '',
        enterpriseId: undefined,
        enterpriseName: '',
      }
    },
    handleChange(value, option) {
      this.form.enterpriseName = option.data.attrs.data.enterpriseName
    },
  },
}
</script>
<style lang="less" scoped></style>
