<template>
  <div class="main-body">
    <div>
      <div class="content">
        <div class="tab-nav flex-wrap flex-h-center">
          <div
            :class="['horn-title-style', tabIndex === 1 ? 'active' : '']"
            @click="changeTab(1)"
          >
            企业认证
          </div>
          <div
            :class="['horn-title-style', tabIndex === 2 ? 'active' : '']"
            @click="changeTab(2)"
          >
            个人认证
          </div>
        </div>
        <div class="tab-content">
          <div v-show="tabIndex === 1">
            <enterprise ref="enterprise" />
          </div>
          <div v-show="tabIndex === 2">
            <personal ref="personal" />
          </div>
        </div>
      </div>
      <div class="footer">
        <a-button class="btn-style" type="primary" @click="onSubmit"
          >认证</a-button
        >
        <a-button class="btn-style" @click="$router.go(-1)"> 取消 </a-button>
      </div>
    </div>
  </div>
</template>
<script>
import Enterprise from './Enterprise'
import Personal from './Personal'
export default {
  components: {
    Enterprise,
    Personal,
  },
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 14 },
      tabIndex: 1,
    }
  },
  methods: {
    changeTab(index) {
      this.tabIndex = index
      this.tabIndex === 1
        ? this.$refs.enterprise.empty()
        : this.$refs.personal.empty()
    },
    onSubmit() {
      this.tabIndex === 1
        ? this.$refs.enterprise.submit()
        : this.$refs.personal.submit()
    },
  },
}
</script>
<style lang="less" scoped>
.main-body {
  background: #f1f1f1;
  padding: 50px 0 130px 0;
  > div {
    width: 1200px;
    margin: 0 auto;

    .content {
      background: #fff;

      .tab-nav {
        border-bottom: 1px solid #ddd;
      }

      /deep/ .tab-content {
        padding: 50px 0;
        .ant-form {
          .item-style {
            border: 1px solid #dcdcdc;
            border-radius: 5px;
            .item-icon {
              width: 70px;
              border-right: 1px solid #ddd;
            }

            .ant-input {
              border: none;
              padding: 20px 15px;

              &:focus {
                box-shadow: none;
              }
            }
          }

          .ant-select {
            .ant-select-selection {
              border: none;
            }
          }

          .ant-select-focused .ant-select-selection,
          .ant-select-selection:focus,
          .ant-select-selection:active {
            box-shadow: none;
          }
        }
      }
    }

    .footer {
      margin-top: 25px;
      background: #fff;
      text-align: center;
      padding: 20px 0;

      .btn-style {
        height: 40px;
        line-height: 40px;
        padding: 0 30px;
        margin: 0 50px;
      }
    }
  }

  .horn-title-style {
    cursor: pointer;
    width: 160px;
    height: 55px;
    line-height: 55px;
    position: relative;
    font-size: 22px;
    font-weight: 400;
    color: #333;
    text-align: center;

    &::before {
      content: '';
      width: 0;
      height: 0;
      border: 10px solid transparent;
      top: 100%;
      left: 50%;
      position: absolute;
      margin-left: -12px;
    }
  }

  .active {
    background-color: #1285d6;
    color: #fff;
    &::before {
      border-top-color: rgb(18, 133, 214);
    }
  }
}
</style>
